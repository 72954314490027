// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { ValueTools }       from '@/Classes/Static/ValueTools'

// Components (.vue)
import InputRut     from '@/Components/Global/InputRut/template.vue'
import SubmitButton from '@/Components/Global/SubmitButton/template.vue'

// Components (Refs)
import { InputRutRef } from '@/Components/Global/InputRut/component'

// Constants
import { Component } from '@/Constants/Component'
import { Server }    from '@/Constants/Server'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const SubUserForm = VueMixins(MixinBase, MixinComponent, MixinFetch, MixinResponsive).extend({
	name: 'SubUserForm',

	components: {
		InputRut,
		SubmitButton
	},

	props: {
		show: Boolean
	},

	data: function() {
		return {
			states: {
				action: Component.Actions.INSERT,
				cert: '',
				certExpiration: '',
				isFetching: false,
				license: '',
				licenseExpiration: '',
				mLastName: '',
				name: '',
				password: '',
				password2: '',
				pLastName: '',
				position: '',
				storageSelected: null as any,
				userStorages: [] as Array<any>
			}
		}
	},

	mounted: function() {
		this._fetchUserStorages()
	},

	computed: {
		_getStorages: function(): Array<any> {
			return this.states.userStorages.map((x) => ({
				value: x._idStorage, text: x.storageName
			}))
		},

		_inputRut: function(): InputRutRef {
			return this.$refs.inputRut as InputRutRef
		},

		_isInserting: function(): boolean {
			return this.states.action === Component.Actions.INSERT
		}
	},

	methods: {
		_fetchUserStorages: async function() {			
			// Realizar la Petición al servidor.
			const { _idUser } = Store.getters.getStoredUser
			const response = await this.fetchURL(Server.Fetching.Method.GET, Server.Routes.Zones.GetStoragesByUser, { _idUser })

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				const userStorages = response.data.body
				this.setStates<SubUserFormRef['states']>({ userStorages })
				if (userStorages?.length > 0) this.setStates<SubUserFormRef['states']>({ storageSelected: userStorages[0]._idStorage })
			}
		},

		_getDisableIfStorageNull: function() {
			return ValueTools.isNullOrUndefined(this.states.storageSelected)
		},
		
		_getEmptyDateClass: function(propName: string) {
			return (<any> this.states)[propName] === '' ? 'empty' : ''
		},
		
		_getSubmitButtonText: function() {
			return `${ this.states.action === Component.Actions.INSERT ? 'Crear' : 'Actualizar' } Usuario`
		},

		_validateInputRutDisable: function() {
			return (this.states.action === Component.Actions.UPDATE) || this._getDisableIfStorageNull()
		},

		clear: function() {
			this.setStates<SubUserFormRef['states']>({
				action: Component.Actions.INSERT,
				cert: '',
				certExpiration: '',
				license: '',
				licenseExpiration: '',
				mLastName: '',
				name: '',
				password: '',
				password2: '',
				pLastName: '',
				position: '',
				storageSelected: null
			})
			this._inputRut.clear()
		},
		
		onSUFButtonClick: function(action: string) {
			DevelopmentTools.printWarn('[SubUserForm]:onSUFButtonClick() event triggered')
			
			// Ignorar las validaciones cuando se esta actualizando un operario.
			const sAction = this.states.action
			const { password, password2,name,mLastName,pLastName } = this.states

			if (action === 'submit') {
				// La primera validación comprobará si la acción es UPDATE y si ambas passwords estan vacias.
				if (sAction === Component.Actions.UPDATE) {
					if (password.trim() === '' && password2.trim() === '') {
						this.$emit('onSUFButtonClick', action)
						return
					}
				}

				if (sAction === Component.Actions.INSERT) {
					// Comprobar que ambas contraseñas sean iguales.
					if (password.trim() === '') {
						this.showToast('Contraseña no especificada:', 'El campo contraseña no puede estar vacio!', 'danger')
						return
					}
				}

				if (sAction === Component.Actions.INSERT || sAction === Component.Actions.UPDATE) {
					// El largo de la contraseña debe tener almenos 4 caracteres.
					if (password.trim().length < 4) {
						this.showToast('Contraseña muy corta:', 'El largo de la contraseña debe contener almenos 4 caracteres!', 'danger')
						return
					}

					// Comprobar que ambas contraseñas sean iguales.
					if (password.trim() !== password2.trim()) {
						this.showToast('Verifique contraseñas:', 'Las contraseñas ingresadas no coinciden! Asegurese que ambas contraseñas sean iguales.', 'danger')
						return
					}
				}
			}

			// Emitir el evento.
			this.$emit('onSUFButtonClick', action)
		}
	}
})

// Exports
export default SubUserForm
export type SubUserFormRef = InstanceType<typeof SubUserForm>