// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { RecordHelper }      from '@/Classes/Records/RecordHelper'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/ActionFields'
import { AppValues }    from '@/Constants/AppValues'

// Helpers
import { BuilderManager } from '@/Helpers/Components/DataTable/BuilderManager'

export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idOperator', '_idStorage', '_idRole',  'permissions', 'name', 'pLastName', 'mLastName', 'dateBirth','isValid',  'licenses', 'certifications')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign(null, 'center')
		.add('mOperatorName', 'Nombre Operario').isSortable().setSearchKey('operator.name')
		.add('storageCode', 'Código Planta').isSortable().setAlign(null, 'center').setSearchKey('storage.code')
		.add('storageName', 'Planta').isSortable().setSearchKey('storage.name')
		.add('mLicenses', 'Licencias')
		.add('mCertifications', 'Certificaciones')
		.add('position', 'Cargo').isSortable()
		.add('mIsValid', 'Estado').setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('rut', 'mOperatorName', 'storageCode', 'storageName', 'position', 'mLicenses', 'mCertifications', 'mIsValid')

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(new ItemMissingFields()
		.add('mCertifications')
		.add('mIsValid')
		.add('mLicenses')
		.add('mOperatorName')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		mIsValid: (_: void, item: any) => {
			return item.isValid ? 'Activo' : 'Inactivo'
		},

		mLicenses: (_: never, item: any) => {
			return PrimitiveTools.Arrays.map<any>(item.licenses, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},

		mCertifications: (_: never, item: any) => {
			return PrimitiveTools.Arrays.map<any>(item.certifications, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},

		mOperatorName: (_: void, item: any) => {
			return `${ item.name } ${ item.pLastName }`
		},
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}

export function parseStackedDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields('_idOperator', '_idStorage', '_idRole', 'permissions', 'storageCode', 'storageName', 'mLastName', 'isValid', 'licenses', 'certifications', 'position', 'pLastName')

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign(null, 'center').showDetailsButton()
		.add(`name`, 'Nombre').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields('rut', 'name')

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters({
		name: (_: never, { name, pLastName }: any) => {
			return `${ name } ${ pLastName }`
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail({
		[RecordHelper.setFlags('Código Planta', 'storageCode', 'storage.code')]: ({ storageCode }) => {
			return storageCode || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Planta', 'storageName', 'storage.name')]: ({ storageName }) => {
			return storageName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Cargo', 'position')]: ({ position }) => {
			return position
		},
		[RecordHelper.setFlags('Estado', 'isValid')]: ({ isValid }) => {
			return isValid ? 'Activo' : 'Inactivo'
		},
		['Licencias']: ({ licenses }) => {
			return PrimitiveTools.Arrays.map<any>(licenses, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},
		['Certificaciones']: ({ certifications }) => {
			return PrimitiveTools.Arrays.map<any>(certifications, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return bm.packWithActions()
}